import LanguagesBar from "./components/LanguagesBar";
import TranslationTextField from "./components/TranslationTextField";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Use HashRouter instead of BrowserRouter
import TranslatedText from "components/TranslatedText";
import styled, { ThemeProvider } from "styled-components";
import { palette } from "theme/palette";
import ReactGA from "react-ga";

ReactGA.initialize("G-2RZSZBWP6P");
ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

function App() {
  return (
    <ThemeProvider theme={palette}>
      <h1 className="header ant-select-selection-search-input">
        Open Translation AI
      </h1>
      <Router>
        <Routes>
          <Route
            path="/"
            Component={() => (
              <Container>
                <LanguagesBar />

                <div className="translation-boxes">
                  <TranslationTextField />
                  <TranslatedText />
                </div>
              </Container>
            )}
          />
        </Routes>
      </Router>
      <a
        href="https://www.producthunt.com/posts/open-translation-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-open&#0045;translation&#0045;ai"
        target="_blank"
        className="producthunta"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=452140&theme=dark"
          alt="Open&#0032;Translation&#0032;Ai - AI&#0032;to&#0032;achieve&#0032;a&#0032;human&#0045;like&#0044;&#0032;fluent&#0044;&#0032;and&#0032;perfect&#0032;translation&#0046; | Product Hunt"
          className="prodocthunt"
          width="250"
          height="54"
        />
      </a>
    </ThemeProvider>
  );
}

const Container = styled.div`
  text-align: center;
  color: #ffffff;
  height: 70vh;
  width: 80vw;
  background-color: ${(props) => props.theme.primary.main};
  border-radius: 16px;
  margin: 0 auto;
  margin-top: 10vh;
  overflow: hidden;
  perspective: 1px;
  display: flex;
  flex-direction: column;

  .translation-boxes {
    flex-grow: 1;
    display: flex;
  }

  .translation-boxes div {
    flex: 1;
    height: auto;
  }
`;

export default App;
